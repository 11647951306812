import React, { useEffect, useState ,useRef} from 'react';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './profileV2.css'; // Create and style this CSS file for custom styles
import { decrypt } from "../utils/crypto";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { TieredMenu } from 'primereact/tieredmenu';
import { API_URL } from '../config';

const Profile = ({ setUserLocal}) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const uid = decrypt(localStorage.getItem("uid"));
    const menu = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_URL}/auth/user/${uid}`,{
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                    }
                });
                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <ProgressSpinner />;
    }

    if (!userData) {
        return <p>No user data found for the given mobile number.</p>;
    }
    const userMenuItems = [
        { label: 'Log out', icon: 'pi pi-sign-out', command: () => { setUserLocal(null, null, true); } }
    ];
    const centerContents2 = (<React.Fragment>
         <Button 
                icon="pi pi-home" 
                className="p-button-text" 
                onClick={() => navigate('/')}  // Navigate to home
                style={{ color: '#fff', marginRight: '2px' }} 
            />
        <h2 style={{ margin: 0 }} onClick={() => navigate('/')} >Vault Dashboard</h2>
    </React.Fragment>);
    const topRightContents = (
        <React.Fragment>
            <Button label="About Us" className="p-button-link" style={{ color: '#fff' }} onClick={() => window.location.href = 'https://www.jeeva-ai.com/about-us'} />
            <Button label="Contact Us" className="p-button-link p-ml-2" style={{ color: '#fff' }} onClick={() => window.location.href = 'https://www.jeeva-ai.com/contact-us'} />
            <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#007ad9', color: '#fff' }} onClick={(event) => menu.current.toggle(event)} />
            <TieredMenu model={userMenuItems} popup ref={menu} id="user_menu" />
        </React.Fragment>
    );

    return (
        <div>
            <Toolbar className="p-justify-center" style={{ backgroundColor: '#007ad9', color: '#fff', padding: '5px 0', borderBottom: '1px solid #ddd' }}
                start={centerContents2}
                end={topRightContents}
            ></Toolbar>
            <div className="profile-container">
                <Card title="Profile Information" className="profile-card">
                    <p><strong>Mobile Number:</strong> {userData.uid.substring(2)}</p>
                    <p><strong>Name:</strong> {userData.name}</p>
                    <p><strong>Date of Birth:</strong> {userData.dob}</p>
                </Card>
            </div>
        </div>

    );
};

export default Profile;
