import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Auth.css'; // Import the same CSS file
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { InputOtp } from 'primereact/inputotp';
import { API_URL } from '../config';
// import { format, isValid } from 'date-fns';


import axios from 'axios';

const SignupPage = ({ setUserLocal }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [userStored, setUserStored] = useState(false);
    const [user, setUser] = useState([]);
    const [otp, setOtp] = useState('');
    const [verificationFailed, setVerificationFailed] = useState(false);

    const navigate = useNavigate();
    const toast = useRef(null);
    const goBack = () => {
        navigate('/'); // This will navigate back to the previous page
        // Or navigate to a specific route like navigate('/login-method');
    };
    const validatePhone = (phone) => {
        const phonePattern = /^[6-9]\d{9}$/; // Indian phone number validation
        return phonePattern.test(phone);
    };

    const verifyOtp = async () => {
        try {
            const response = await axios.post(`${API_URL}/auth/verify-otp-login-v2`, {
                phone: ("91" + phone),
                otp: otp,
                name,
                dob,
                password,
                verified: true,
            });
            if (response.data.success) {
                setVerificationFailed(false);
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'OTP verified successfully', life: 3000 });
                setUserLocal("91" + phone, response.data.jwt);
                navigate('/');
                return;
            } else {
                setVerificationFailed(true);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'OTP verification failed', life: 3000 });
            }
        } catch (error) {
            setVerificationFailed(true);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to verify OTP', life: 3000 });
        }
    };

    const sendOtp = async (e) => {
        try {
            const response = await axios.post(`${API_URL}/auth/send-otp-v2`,
                {
                    phone: "91" + phone,
                    usercheckignore: true
                });
            if (response.data.success) {
                setOtp('');
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
            return;
        } catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to send OTP', life: 3000 });
            return;
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!userStored) {
            setUserStored(true);
            setUser([{
                name,
                phone: "91" + phone,
                dob,
                password,
            }]);
            sendOtp();
            return;
        }

        if (password !== confirmPassword) {
            setPasswordError(true);
            return;
        }
        if (!validatePhone(phone)) {
            setPhoneError(true);
            return;
        }
    };
    return (
        <div >
            <Toast ref={toast} />
            <div className="welcome-text">Welcome To Jeeva AI</div>
            {!userStored && (
                <>
                    <Card className="card">
                        <h2>Sign Up</h2>
                        <form onSubmit={handleSignup} className="p-fluid">
                            <div className="p-field">
                                <InputText
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="inputFullWidth"
                                    placeholder='Name'
                                    required
                                />
                                {/* <span className="required-asterik">*</span> */}
                            </div>
                            <div className="p-field">
                                <InputText
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="inputFullWidth"
                                    placeholder='Phone'
                                    required
                                />
                                {/* <span className="required-asterik">*</span> */}
                                {phoneError && (
                                    <small className="p-error" style={{ color: 'red' }}>
                                        {"Invalid Mobile Number"}
                                    </small>
                                )}
                            </div>
                            <div className="p-field">
                                <Calendar
                                    id="dob"
                                    value={dob}
                                    onChange={(e) => {
                                        setDob(e.value);
                                    }}
                                    dateFormat="dd/mm/yy"
                                    className="inputFullWidth"
                                    placeholder='Date of Birth (DD/MM/YYYY)'
                                    required
                                />
                                {/* <span className="required-asterik">*</span> */}
                            </div>
                            <div className="p-field">
                                <Password
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="inputFullWidth"
                                    placeholder='Password'
                                    feedback={false}
                                    required
                                />
                                {/* <span className="required-asterik">*</span> */}
                            </div>
                            <div className="p-field">
                                <Password
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="inputFullWidth"
                                    placeholder='Confirm Password'
                                    feedback={false}
                                    required
                                />
                                {/* <span className="required-asterik">*</span> */}
                                {passwordError && (
                                    <small className="p-error" style={{ color: 'red' }}>
                                        {"Password Mismatch"}
                                    </small>
                                )}
                            </div>
                            <Button type="submit" label="Sign Up" className="buttonFullWidth" />
                            <Button label="Log In?" onClick={goBack} severity="info" text />
                        </form>


                    </Card></>
            )}
            {userStored && (
                <>
                    <Card className="card">
                        <h2>Verify Mobile Via OTP</h2>
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="otp">OTP:</label>
                                <InputOtp
                                    id="otp"
                                    value={otp}
                                    onChange={(e) => setOtp(e.value)}
                                    className="inputFullWidth"
                                />
                            </div>
                            <Button label="Log In" onClick={verifyOtp} className="buttonFullWidth" />
                            {verificationFailed && (
                                // <Button label="Resend OTP" onClick={reSendOtp} className="buttonFullWidth p-button-secondary" />
                                <Button label="Resend Code" onClick={sendOtp} link className="p-0"></Button>
                            )}
                            <Button label="Back" onClick={goBack} severity="info" text />
                        </div>
                    </Card>
                </>
            )}

        </div>
    );
};

export default SignupPage;
