import React, { useRef, useEffect, useState } from "react";
import HeaderV2 from '../Components/HeaderV2';
import NotFound from "../Components/NotFound";
import DocumentCard from '../Components/DocumentCardV2';
import UploadDialog from "../Components/UploadDialog";
import Chatbot from '../Components/chatbotV2';
import SidebarV2 from '../Components/Sidebarv2'
import DocumentViewerV2 from "../Components/docViewerV2";
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { decrypt } from "../utils/crypto";
import OverwriteTagsDialog from '../Components/OverwriteTagsDialog'
import UploadStatusCard from "../Components/UploadStatusCard";
import { Toast } from 'primereact/toast';
import './Main.scss';
import { API_URL } from "../config";
import { ProgressSpinner } from 'primereact/progressspinner';

const Main = ({ setUserLocal }) => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [category, setCategory] = useState('medical');
  const [searchValue, setSearchValue] = useState('');
  const [visibleSidebar, setVisibleSidebar] = useState(true);
  const uid = decrypt(localStorage.getItem("uid"));
  const [showSecondHeader, setShowSecondHeader] = useState(true);
  const [showOverwriteDialog, setShowOverwriteDialog] = useState(false);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'default';
  });
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('uploading'); // Manage the status of the entire upload process
  const [message, setMessage] = useState('Uploading your documents...');
  const [showStatusCard, setShowStatusCard] = useState(false);
  const [docsWithMissingNames, setDocsWithMissingNames] = useState([]);
  const [showSummaryIcon, setShowSummaryIcon] = useState(false);
  const [files, setFiles] = useState(null);
  const isInitialRender = useRef(true);
  const toast = React.useRef(null);

  useEffect(() => {
    const savedDoc = localStorage.getItem('selectedDoc');
    if (savedDoc) {
      setSelectedDoc(JSON.parse(savedDoc));
    }
    // const summary = await summaryModel({uid})
    // setMedSummry(summary);
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Disable the flag after the first render
    } else {
      localStorage.setItem('viewMode', viewMode);
      if (selectedDoc) {
        localStorage.setItem('selectedDoc', JSON.stringify(selectedDoc));
      }
    }
  }, [viewMode, selectedDoc]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    // Run on component mount and resize
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleStartUpload = () => {
    setShowStatusCard(true);
    setStatus('uploading');
    setMessage('Uploading your documents...');
  };

  const handleDocClick = (doc) => {
    setSelectedDoc(doc);
    setViewMode('preview');
    setShowSecondHeader(false);
    setVisibleSidebar(false);
  };

  const toggleSidebar = () => {
    setVisibleSidebar(!visibleSidebar); // Toggle sidebar visibility
  };

  useEffect(() => {
    applyFilters();
  }, [category, searchValue, documents]);

  const applyFilters = () => {
    let filtered = documents;

    if (category !== 'all') {
      filtered = filtered.filter(doc => doc.category === category);
    }
    if(category === 'medical')setShowSummaryIcon(true);
    else setShowSummaryIcon(false);
    if (searchValue) {
      filtered = filtered.filter(doc => {
        const docTitle = doc?.tag?.general_tags?.title ? doc?.tag?.general_tags?.title : '';
        const metaData = {
          fileName: doc?.title ? doc?.title : '',
          author: doc?.tag?.general_tags?.author ? doc?.tag?.general_tags?.author : '',
          category: doc?.tag?.general_tags?.category ? doc?.tag?.general_tags?.category : '',
          name: doc?.tag?.general_tags?.name ? doc?.tag?.general_tags?.name : '',
          date_created: doc?.tag?.general_tags?.date_created ? doc?.tag?.general_tags?.date_created : '',
          sub_category: doc?.tag?.general_tags?.sub_category ? doc?.tag?.general_tags?.sub_category : '',
          department: doc?.tag?.general_tags?.department ? doc?.tag?.general_tags?.department : '',
          description: doc?.tag?.general_tags?.brief_summary ? doc?.tag?.general_tags?.brief_summary : '',
        };
        return (
          docTitle.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.fileName.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.author.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.category.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.date_created.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.sub_category.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.department.toLowerCase().includes(searchValue.toLowerCase()) ||
          metaData.description.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    setFilteredDocs(filtered);
  };

  const handleCloseView = () => {
    setSelectedDoc(null);
    setViewMode('default');
    setShowSecondHeader(true);
    localStorage.removeItem('viewMode');  // Clear viewMode on back
    localStorage.removeItem('selectedDoc');  // Clear selectedDoc on back
  };

  const handleChatCloseView = () => {
    setSelectedDoc(null);
    setViewMode('default');
    setShowSecondHeader(true);
    localStorage.removeItem('viewMode');
    localStorage.removeItem('selectedDoc');
  };

  const handleHideSidebar = () => {
    setVisibleSidebar(false); // Simply hide the sidebar without changing the view
  };


  const handleMenuSelect = (doc, action) => {
    const docId = doc._id;
    console.log(`Menu action: ${action} for document ID: ${docId}`);
    if (action === 'delete') {
      fetch(`${API_URL}/vault/records/remove/${docId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': localStorage.getItem('token'),
        }
      })
        .then(res => res.json())
        .then(() => {
          setDocuments(prevDocuments => prevDocuments.filter(doc => doc._id !== docId));
          setDocsWithMissingNames(prevDocuments => prevDocuments.filter(doc => doc._id !== docId));
        })
        .catch(err => console.error("Error deleting document:", err));
    }
    if (action === 'view') {
      handleDocClick(doc);
    }
    if (action === 'find') {
      setSelectedDoc(doc);
      setViewMode('chatbot');
      setShowSecondHeader(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "";
    setLoading(true);
    fetch(`${API_URL}/vault/records/${uid}`, {
      headers: {
        'Authorization': localStorage.getItem('token'),
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setDocuments(data);
        setLoading(false);
        const missingDocNames = data.filter(doc => {
          if (doc?.tag?.general_tags?.name) {
            return false;
          }
          return true;
        });
        setDocsWithMissingNames(missingDocNames);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error in fetching or posting data:", error);
      }).finally(() => {
        setLoading(false);
      });;
    ;

  }, [uid]);

  const handleUploadComplete = async (e) => {
    setShowUploadDialog(false);
    if (!files) {
      toast.current.show({ severity: 'error', summary: 'Missing Information', detail: 'No Files Selected', life: 3000 });
      return;
    }
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    try {
      let response = await fetch(`${API_URL}/vault/uploadMultipleFiles?uid=${uid}`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': localStorage.getItem('token'),
        }
      });
      if (response.status === 200) {
        response = await response.json();
        setStatus('success');
        setMessage(response.message || 'Upload Complete');
      } else {
        response = await response.json();
        setStatus('error');
        setMessage(response.message || 'Uploading Failed');
      }
    } catch (error) {
      setStatus('error');
      setMessage('Uploading Failed');
      console.error('Error uploading files:', error);
      toast.current.show({ severity: 'error', summary: 'Upload Failed', detail: error.message || 'There was an issue uploading your files.', life: 3000 });
    } finally {

    }
  };

  const handleUpdateDocument = (docId, newName) => {
    // Update the document's name in the state
    setDocuments(prevDocuments =>
      prevDocuments.map(doc => {
        if (doc._id === docId) doc.extraInfo.name = newName;
        return doc;
      })
    );
  };


  return (
    <>

      <HeaderV2
        handleCloseView={handleCloseView}
        onUploadClick={() => setShowUploadDialog(true)} onCategoryChange={setCategory}
        onSearch={
          (query) => { setSearchValue(query); }
        }
        setUserLocal={setUserLocal} mobile={uid} showSecondHeader={showSecondHeader}
        setFilteredDocs={setFilteredDocs}
        documents={documents}
        setShowOverwriteDialog={setShowOverwriteDialog}
        docsWithMissingNames={docsWithMissingNames}
        showSummaryIcon = {showSummaryIcon}
      />
      <Toast ref={toast} />
      <div className="page-content">
        {viewMode === 'chatbot' && selectedDoc ? (
          <Chatbot
            documents={filteredDocs}
            selectedDoc={selectedDoc}
            onBack={handleChatCloseView}
            mobile={uid}
            setShowSecondHeader={setShowSecondHeader}


          />
        ) : ((viewMode === 'preview' && selectedDoc) ? (
          <div className="preview-container">
            <SidebarV2
              documents={filteredDocs}
              onDocumentSelect={handleDocClick}
              onBack={handleCloseView}
              title="Preview Documents"
              visible={visibleSidebar}
              onHideSidebar={handleHideSidebar}
              toggleSidebar={toggleSidebar}
            />
            <div className="document-content">
              <div className="preview-header">
                <Button icon="pi pi-arrow-left" className="p-button-text" onClick={handleCloseView} style={{ color: '#fff' }} />
                <Button icon="pi pi-bars" className="p-button-text toggle-sidebar-btn" onClick={toggleSidebar} style={{ color: '#fff' }} />
                <h2>{selectedDoc.title}</h2>
              </div>
              {isMobile ? (
                <DocumentViewerV2 s3Url={selectedDoc.s3url} />
              ) : (
                <iframe
                  src={`${selectedDoc.s3url}`}
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="p-grid" style={{ padding: '20px' }}>
            {filteredDocs && filteredDocs.map(doc => (
              <div key={doc._id} className="p-col-12 photo-card p-md-6 p-lg-3">
                <DocumentCard
                  documentData={doc}
                  userMobile={uid}
                  onInfoClick={() => console.log("Info clicked for document ID:", doc._id)}
                  onMenuSelect={(action) => handleMenuSelect(doc, action)}
                  setShowOverwriteDialog={setShowOverwriteDialog}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      {
        loading && (<div className="docloader">
          <ProgressSpinner />
        </div>)
      }
      {filteredDocs && filteredDocs.length === 0 && !loading && (
        <>
          <NotFound text={`Sorry, No Records Present!`} />
        </>
      )}
      <ConfirmDialog />
      {showStatusCard && (
        <UploadStatusCard
          status={status}
          message={message}
          onClose={() => {
            setShowStatusCard(false);
            window.location.reload();
          }}
        />
      )}
      <OverwriteTagsDialog
        visible={showOverwriteDialog}
        onHide={() => {
          setShowOverwriteDialog(false);

        }}
        documents={docsWithMissingNames}
        onUpdateDocument={handleUpdateDocument}
        setDocsWithMissingNames={setDocsWithMissingNames}
      />
      <UploadDialog
        visible={showUploadDialog}
        onHide={() => setShowUploadDialog(false)}
        onUploadStart={handleStartUpload}
        onUploadComplete={handleUploadComplete}
        uid={uid}
        setFiles={setFiles}
      />
    </>
  );
};

export default Main;
