import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { API_URL } from '../config';
import './chatbotV2.css';

const Chatbot = ({ documents, selectedDoc, onBack, mobile, setShowSecondHeader }) => {
    const [selectedDocs, setSelectedDocs] = useState([selectedDoc._id]);
    const [conversation, setConversation] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [visibleSidebar, setVisibleSidebar] = useState(false);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [conversation]);

    useEffect(() => {
        setConversation([{ message: "Hi, Welcome to Jeeva vault, how can I help you today?", sender: "bot" }]);
    }, []);
    const handleDocSelection = (docId) => {
        setSelectedDocs(prevSelectedDocs =>
            prevSelectedDocs.includes(docId)
                ? prevSelectedDocs.filter(id => id !== docId)
                : [...prevSelectedDocs, docId]
        );
    };

    const handleSelectAll = (e) => {
        if (e.checked) {
            setSelectedDocs(documents.map(doc => doc._id));
        } else {
            setSelectedDocs([]);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        const newConversation = [...conversation, { message: userMessage, sender: "user" }];
        setConversation(newConversation);
        const question = userMessage;
        const phone = mobile;
        const docIds = selectedDocs;
        setUserMessage('');
        
        // Simulate an API call
        // setTimeout(() => {
        //     const botReply = {
        //         message: `You asked: "${userMessage}". This is a simulated response.`,
        //         sender: "bot"
        //     };
        //     setConversation([...newConversation, botReply]);
        // }, 1000);
       
        fetch(`${API_URL}/vault/records/chat?input=${question}&docIds=${docIds}&phone=${phone}`,{
            headers: {
                'Authorization': localStorage.getItem('token'),
            }
        })
        .then((res) => res.json())
        .then((data) => {
            setConversation([...newConversation, { message: data, sender: "bot" }]);
        });
    };

    return (
        <div className="chatbot-container">
            {/* Sidebar Implementation */}
            <Sidebar visible={visibleSidebar} onHide={() => setVisibleSidebar(false)} position="left" className="custom-sidebar">
                <div className="flex flex-column gap-3 p-3">
                    <div className="flex align-items-center">
                        <Checkbox
                            inputId="selectAll"
                            name="selectAll"
                            onChange={handleSelectAll}
                            checked={selectedDocs.length === documents.length}
                        />
                        <label htmlFor="selectAll" className="ml-2">
                            Select All
                        </label>
                    </div>
                    {documents.map((doc) => (
                        <div key={doc._id} className="flex align-items-center">
                            <Checkbox
                                inputId={doc._id}
                                name="document"
                                value={doc._id}
                                onChange={() => handleDocSelection(doc._id)}
                                checked={selectedDocs.includes(doc._id)}
                            />
                            <label htmlFor={doc._id} className="ml-2">
                                {doc?.tag?.general_tags?.title}
                            </label>
                        </div>
                    ))}
                </div>
            </Sidebar>

            {/* Chatbot Header */}
            <div className="chatbot-header">
                <Button icon="pi pi-arrow-left" className="p-button-text" onClick={onBack} style={{ color: '#fff' }}/>
                <Button icon="pi pi-bars" className="p-button-text" onClick={() => setVisibleSidebar(true)} style={{ color: '#fff' }}/>
                <h2>Chatbot</h2>
            </div>

            {/* Chat Window */}
            <div className="chat-window">
                <div className="chat-messages">
                    {conversation.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.sender}`}>
                            <p>{msg.message}</p>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />  {/* Scroll target */}
                </div>
                <div className="chat-input">
                    <InputText value={userMessage} onChange={(e) => setUserMessage(e.target.value)} placeholder="Type your message..."  onKeyDown={handleKeyPress} />
                    <Button icon="pi pi-send" className="p-button-text" onClick={handleSendMessage} />
                </div>
            </div>
        </div>
    );
};

export default Chatbot;
