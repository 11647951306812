import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { calculateAge } from '../utils/common';
import { Toast } from 'primereact/toast';
import { API_URL } from '../config';


const VoiceRecorderDialog = ({ mobile, userData }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(false); // Loader during API call
    const [responseData, setResponseData] = useState(null); // Store API response
    const [editableData, setEditableData] = useState({}); // Editable table data
    const [recordingState, setRecordingState] = useState('initial'); // 'initial', 'recording', 'uploading'
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString());
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const toast = useRef(null);

    // Simulate an API call to fetch user info


    const openDialog = () => {
        setShowDialog(true);
        setRecordingState('initial');  // Reset the dialog to initial state
    };

    const closeDialog = () => {
        setShowDialog(false);
        setResponseData(null);  // Reset dialog state
        setEditableData({"Diagnosis":"", "Investigation":"", "Problem and Symptoms":"", "Care plan":""});  // Reset editable data
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                // const jsonBlob = new Blob([JSON.stringify(responseData)], { type: 'application/json' });
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });

                // Create form data for audio file
                const formData = new FormData();
                formData.append('audio', audioFile);
                formData.append('data', JSON.stringify(responseData));

                try {
                    setLoading(true); // Start loader
                    setRecordingState('uploading'); // Set state to uploading

                    // Make API call to process audio file using fetch
                    const response = await fetch(`${API_URL}/voice/process-audio`, {
                        method: 'POST',
                        body: formData,
                    });

                    if (response.ok) {
                        const jsonResponse = await response.json();
                        setResponseData(jsonResponse);  // Set API response
                        setEditableData(jsonResponse);  // Set editable data for table
                    }
                    // } else {
                    //     console.error('Failed to upload audio');
                    // }
                } catch (error) {
                    console.error('Error processing audio:', error);
                } finally {
                    setLoading(false); // Stop loader after API call completes
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            setRecordingState('recording'); // Change state to "recording"
        } catch (err) {
            console.log(err, 'err in recording');
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    // Handle updating table inputs
    const handleInputChange = (key, value) => {
        setEditableData({
            ...editableData,
            [key]: value,
        });
    };

    // Submit updated data
    const handleSubmit = async () => {
        try {
            // Make API call to submit the updated JSON data
            const response = await fetch(`${API_URL}/voice/generate-report`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ meta_data: editableData, uid: mobile })
            });
            if (response.ok) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Report Generated Successfully!', life: 3000 });
                console.log('Data submitted successfully!');
            } else {
                console.error('Failed to submit updated data');
            }
        } catch (error) {
            console.error('Error submitting updated data:', error);
        }
    };

    // Custom header with a close button and Try Again button
    const headerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <h2 style={{ margin: 0, textAlign: 'center', flex: 1 }}>Voice Report</h2>
            <div>
                {responseData && (
                    <Button
                        icon="pi pi-microphone"
                        label="Modify"
                        className="p-button-rounded p-button-secondary"
                        style={{ marginRight: '10px' }}
                        onClick={startRecording}
                    />
                )}
                <Button icon="pi pi-times" className="p-button-rounded p-button-text" onClick={closeDialog} />
            </div>
        </div>
    );

    return (
        <>
    <Toast ref={toast} />
    <Button
        className="p-button-rounded p-button-text"
        onClick={openDialog}
        style={{ backgroundColor: '#007ad9', color: '#fff' }}>
        <img
            src="/miclogo.jpg"
            alt="Mic Logo"
            style={{ width: '24px', height: '24px' }}
        />
    </Button>

    <Dialog
        header={headerContent}
        visible={showDialog}
        style={{ width: '90vw', height: '75vh', maxWidth: '450px', position: 'relative' }}
        onHide={closeDialog}
        closable={false}
    >
        {/* Custom close button at the top-right corner */}

        <div style={{ textAlign: 'center', marginTop: '40px', position: 'relative' }}>
            {/* Initial state with microphone icon and "Start Speaking" button */}
            {recordingState === 'initial' && (
                <>
                    <i className="pi pi-microphone" style={{ fontSize: '3rem', color: '#d32f2f' }}></i>
                    <Button
                        label="Click To Start"
                        className="p-button-rounded p-button-danger"
                        style={{ marginTop: '20px', width: '80%' }}
                        onClick={startRecording}
                    />
                </>
            )}

            {/* Recording state with "Listening..." message and "Generate Report" button */}
            {recordingState === 'recording' && (
                <>
                    <h2>Listening...</h2>
                    <Button
                        label="Click To Generate Report"
                        className="p-button-rounded p-button-success"
                        style={{ marginTop: '20px', width: '80%' }}
                        onClick={stopRecording}
                    />
                </>
            )}

            {/* Uploading state with loader and message */}
            {recordingState === 'uploading' && loading && (
                <>
                    <h2>Please wait while we upload...</h2>
                    <ProgressSpinner style={{ marginTop: '20px' }} />
                </>
            )}

            {/* Render the User Info (Name, Age, Date) only if responseData exists */}
            {responseData && (
                <div style={{ textAlign: 'left', width: '100%', marginBottom: '30px' }}>
                    <table
                        className="responsive-table"  // Apply a class to differentiate styling
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            marginBottom: '20px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <thead>
                            <tr style={{ backgroundColor: '#f5f5f5' }}>
                                <th style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                }}>
                                    Name
                                </th>
                                <th style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                }}>
                                    Age
                                </th>
                                <th style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                }}>
                                    Date
                                </th>
                            </tr>
                            <tr>
                                <td style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                }}>
                                    {userData.name}
                                </td>
                                <td style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                }}>
                                    {calculateAge(userData.dob)}
                                </td>
                                <td style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                }}>
                                    {todayDate}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            )}

            {/* Editable data table */}
            {responseData && (
                <div style={{ marginTop: '20px', textAlign: 'left', width: '100%' }}>
                    <table
                        className="responsive-table"  // Same class for consistent styling
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            marginBottom: '20px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <tbody>
                            {Object.keys(editableData).map((key) => (
                                <tr key={key}>
                                    <td style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        width: '30%',  // Give less width to the label column
                                        wordWrap: 'break-word',
                                    }}>
                                        {key}
                                    </td>
                                    <td colSpan="2" style={{
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        textAlign: 'left',
                                        width: '70%',  // More width to the editable content column
                                        wordWrap: 'break-word',  // Ensure long content wraps
                                    }}>
                                        <input
                                            type="text"
                                            value={editableData[key]}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                            style={{
                                                width: '100%',  // Ensure input takes full width of the cell
                                                padding: '8px',
                                                border: '1px solid #ccc',
                                                outline: 'none',
                                                backgroundColor: '#f9f9f9',
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Submit button */}
            {responseData && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                        label="Submit"
                        className="p-button-rounded p-button-primary"
                        style={{ width: '80%' }}
                        onClick={handleSubmit}
                    />
                </div>
            )}
        </div>
    </Dialog>

    {/* Add responsive CSS styles */}
    <style jsx>{`
        /* Media query for mobile */
        @media (max-width: 768px) {
            .responsive-table {
                table-layout: fixed;  /* Fixed layout for mobile to ensure wrapping */
                word-wrap: break-word;
            }

            /* Ensure editable content gets more width in mobile view */
            .responsive-table td:nth-child(2) {
                width: 70%;
            }
        }

        /* Media query for laptop/desktop */
        @media (min-width: 769px) {
            .responsive-table {
                table-layout: auto;  /* Auto layout for larger screens to distribute width naturally */
            }
        }
    `}</style>
</>

    );
};

export default VoiceRecorderDialog;
