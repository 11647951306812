import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Auth.css'; // Import the same CSS file
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { InputOtp } from 'primereact/inputotp';
import { API_URL } from '../config';

const LoginViaPassword = ({ mobile, setUserLocal }) => {
    const [password, setPassword] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useRef(null);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');

    const goBack = () => {
        navigate('/'); // This will navigate back to the previous page
        // Or navigate to a specific route like navigate('/login-method');
    };

   

    const login = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/auth/login`, {
                phone: ("91" + mobile),
                password: password
            });
            if (response.data.success) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                setUserLocal("91"+mobile, response.data.jwt);
                window.location.reload();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed To Login', life: 3000 });
        }
    };

    const sendOtp = async () => {
        try {
            const response = await axios.post(`${API_URL}/auth/send-otp-v2`, { phone: "91" + mobile });
            if (response.data.success) {
               setOtpSent(true);
               toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
               return;
            } else {
              //  setOtpSent(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
            setOtpSent(false);
            return;
        } catch (error) {
            setOtpSent(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to send OTP', life: 3000 });
            return;
        }
    };

    const reSendOtp = async () => {
        try {
            const response = await axios.post(`${API_URL}/auth/send-otp-v2`, { phone: "91" + mobile });
            if (response.data.success) {
               setOtp('');
               setOtpSent(true);
               toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
               return;
            } else {
              //  setOtpSent(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
            setOtpSent(false);
            return;
        } catch (error) {
            setOtpSent(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to send OTP', life: 3000 });
            return;
        }
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        setResetPassword(true);
        sendOtp();
    };

    const verifyOtp = async() => {
        try {
            const response = await axios.post(`${API_URL}/auth/verify-otp-login-v2`, {
                phone: ("91"+mobile),
                otp: otp
            });      
            if (response.data.success) {
                setOtpVerified(true);
                setVerificationFailed(false);
            
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'OTP verified successfully', life: 3000 });
            } else {
               // setOtpVerified(false);
               setVerificationFailed(true);
            setOtpVerified(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'OTP verification failed', life: 3000 });
            }
        } catch (error) {
            setOtpVerified(false);
            setVerificationFailed(true);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to verify OTP', life: 3000 });
        }
    };

    const handleResetPasswordv2 = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Passwords do not match', life: 3000 });
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/auth/reset-password`, {
                phone: ("91"+mobile),
                newPassword: password
            }); 
            if (response.data.success) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Password reset successfully', life: 3000 });
                setUserLocal("91"+mobile, response.data.jwt);
                navigate('/');

            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to reset password', life: 3000 });
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="welcome-text">Welcome To Jeeva AI</div>
            {!resetPassword && (
                <><Card className="card">
                    <h2>Log In Via Password</h2>
                    <div className="p-fluid">
                        <div className="p-field">
                            <InputText
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="inputFullWidth"
                                placeholder='Password'
                            />
                        </div>
                    </div>
                    <Button label="Log In" onClick={login} className="buttonFullWidth" />
                    <Button label="Reset Password" onClick={handleResetPassword} link className="p-0"></Button>

                </Card></>
            )}
            {otpSent && !otpVerified && (
                <>
                    <Card className="card">
                        <h2>Reset Password Via OTP</h2>
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="otp">OTP:</label>
                                <InputOtp
                                    id="otp"
                                    value={otp}
                                    onChange={(e) => setOtp(e.value)}
                                    className="inputFullWidth"
                                />
                            </div>
                            <Button label="Continue" onClick={verifyOtp} className="buttonFullWidth" />
                            {verificationFailed && (
                                // <Button label="Resend OTP" onClick={reSendOtp} className="buttonFullWidth p-button-secondary" />
                                <Button label="Resend Code" onClick={reSendOtp} link className="p-0"></Button>
                            )}
                        </div>
                    </Card>
                </>
            )}
            {otpVerified && (
                <>
                    <Card className="card">
                    <h2>Reset Password</h2>
                    <div className="p-fluid">
                        <div className="p-field">
                            <InputText
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="inputFullWidth"
                                placeholder='Password'
                            />
                        </div>
                        <div className="p-field">
                            <InputText
                                id="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="inputFullWidth"
                                placeholder='Confirm Password'
                            />
                        </div>
                    </div>
                    <Button label="Reset Password" onClick={handleResetPasswordv2} className="buttonFullWidth" />
                </Card>
                </>
            )}
            <Button label="Back" onClick={goBack} severity="info" text />

        </div>
    );
};

export default LoginViaPassword;
