import React from 'react';
import './Summary.css'; // Optional: external CSS for styling

const Summary = ({ data }) => {

  // Recursive function to render JSON in bullet/numbered list format
  const renderSummary = (obj, level = 0) => {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      return (
        <ul>
          {Object.keys(obj).map((key, index) => (
            <li key={index}>
              <strong>{key}:</strong>
              {typeof obj[key] === 'object' ? (
                renderSummary(obj[key], level + 1)
              ) : (
                <span> {obj[key]}</span>
              )}
            </li>
          ))}
        </ul>
      );
    } else if (obj && Array.isArray(obj)) {
      return (
        <ol>
          {obj.map((item, index) => (
            <li key={index}>{renderSummary(item, level + 1)}</li>
          ))}
        </ol>
      );
    } else {
      return <span>{obj}</span>;
    }
  };

  return (
    <div className="summary-container">
      <h2>Medical Summary</h2>
      {renderSummary(data)}
    </div>
  );
};

export default Summary;
