import React, { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';
import {CardComponent} from "../Components/CardComponent"
import ShareDialog from './ShareDialogV2';

const DocumentCard = ({ documentData, onInfoClick, onMenuSelect, userMobile,setShowOverwriteDialog }) => {
    const [selectedFileName, setSelectedFileName] = useState('');
    const [showShareDialog, setShowShareDialog] = useState(false);
    const menu = useRef(null);
    const toast = useRef(null);

    const [showInfo, setShowInfo] = useState(false);
    documentData.extraInfo = {
        fileName: documentData?.title,
        author: documentData?.tag?.general_tags?.author ? documentData?.tag?.general_tags?.author : 'null',
        category: documentData?.tag?.general_tags?.category ? documentData?.tag?.general_tags?.category : 'null',
        // name: documentData?.tag?.general_tags?.name ? documentData?.tag?.general_tags?.name : 'null',
        date_created: documentData?.tag?.general_tags?.date_created ? documentData?.tag?.general_tags?.date_created : 'null',
        sub_category: documentData?.tag?.general_tags?.sub_category ? documentData?.tag?.general_tags?.sub_category : 'null',
        department: documentData?.tag?.general_tags?.department ? documentData?.tag?.general_tags?.department : 'null',
    };
    let brief_summary = 'Unavailable';
    if (documentData?.tag?.general_tags?.brief_summary) brief_summary = documentData?.tag?.general_tags?.brief_summary;
    let { title, s3url, extraInfo } = documentData;
    title = documentData?.tag?.general_tags?.title ? documentData?.tag?.general_tags?.title : title;

    // Truncate title to 10 characters if longer
    title = title.substring(0, title.lastIndexOf('.')).length > 10 ? `${title.substring(0, title.lastIndexOf('.')).slice(0, 15)}${title.substring(title.lastIndexOf('.'))}` : title;

    const menuItems = [
        {
            label: 'View',
            command: () => onMenuSelect('view') // Open the PDF/image in a new tab
        },
        {
            label: 'Delete',
            command: () => {
                confirmDialog({
                    message: 'Are you sure you want to delete this document?',
                    header: 'Delete Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => onMenuSelect('delete'),
                    reject: () => console.log('Delete rejected')
                });
            }
        },
        { label: 'Find in document', command: () => onMenuSelect('find') },
        {
            label: 'Share',
            command: () => {
                setSelectedFileName(documentData.tag.general_tags.title || 'Unnamed File');
                setShowShareDialog(true);
            }
        }
    ];

    const toggleInfo = () => setShowInfo(!showInfo);

    return (
        <>
         <CardComponent
            img='https://picsum.photos/id/54/400/300'
            author='Nancy Wheeler' 
            extraInfo={extraInfo}
            documentData={documentData}
            title={title}
            menuItems={menuItems}
            showInfo={showInfo}
            brief_summary={brief_summary}
            toggleInfo={toggleInfo}
            onMenuSelect={onMenuSelect}
          />
        <ShareDialog
            visible={showShareDialog}
            onHide={() => setShowShareDialog(false)}
            fileName={selectedFileName}
            userMobile={userMobile}
            existingPassword={documentData.docpassword}
            docId={documentData._id}
        />
        </>

    );
};

export default DocumentCard;
