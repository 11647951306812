import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Summary from './Summary';

const SummaryDialog = ({ showInfoDialog, closeDialog, documents, username, userage, isMobile,reportsummary }) => {
  const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  
  return (
    <Dialog
      header={<h2 style={{ textAlign: 'center', marginBottom: '0px' }}>Summary</h2>}
      visible={showInfoDialog}
      style={{ width: isMobile ? '90vw' : '70vw', padding: '0px' }}
      onHide={closeDialog}
      modal
    >
      <div style={{ marginBottom: '0px', display: 'flex', justifyContent: 'center' }}>
        <table
          style={{
            width: '50%',
            borderCollapse: 'separate',
            borderSpacing: '0',
            marginTop: '0px',
            marginBottom: '20px',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  backgroundColor: '#f5f5f5',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                Name
              </th>
              <th
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  backgroundColor: '#f5f5f5',
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '10px',
                }}
              >
                Age
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  borderBottomLeftRadius: '10px',
                }}
              >
                {username}
              </td>
              <td
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  borderBottomRightRadius: '10px',
                }}
              >
                {userage}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="document-summary-list">
        {Object.keys(reportsummary).map((category, index) => (
          <Summary data={(reportsummary[category].length === 0) ? reportsummary[category]: JSON.parse(reportsummary[category])}/>
          // <div key={index} className="document-summary" style={{ marginBottom: '20px' }}>
          //   <h1 style={{ marginBottom: '5px', fontWeight: 'bold' }}>{capitalizeFirstChar(category)}</h1>
          //   <p1>{(reportsummary[category].length) ? reportsummary[category]: "No summary found"}</p1>
          // </div>
        ))}
      </div>
      <Button label="Close" onClick={closeDialog} />
    </Dialog>
  );
};

export default SummaryDialog;
