import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import './OverwriteTagsDialog.css';
import { API_URL } from '../config';

const OverwriteTagsDialog = ({ visible, onHide, documents, onUpdateDocument, setDocsWithMissingNames }) => {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [updatedName, setUpdatedName] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [placeholder, setPlaceholder] = useState('Enter a name');
    useEffect(() => {
        if (selectedDocument) {
            setUpdatedName(selectedDocument?.tag?.general_tags?.name || '');
        }
    }, [selectedDocument]);

    // Function to handle when a document is clicked
    const handleDocumentClick = (doc) => {
        setSelectedDocument(doc);
    };

    // Function to handle updating the document name
    const handleUpdate = async () => {
        if (updatedName.trim() !== '') {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/vault/records/${selectedDocument._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('token'),
                    },
                    body: JSON.stringify({ updateBody: [{ key: 'tag.general_tags.name', value: updatedName }] }),
                });

                if (response.ok) {
                    onUpdateDocument(selectedDocument._id, updatedName);
                    
                    setDocsWithMissingNames((prev) => prev.filter(doc => doc._id !== selectedDocument._id));
                   
                    setSelectedDocument(null);
                    setUpdatedName('');
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Name updated successfully!', life: 3000 });
                } else {
                    console.error('Failed to update the document name.');
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update name.', life: 3000 });
                }
            } catch (error) {
                console.error('Error updating document:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update name.', life: 3000 });
            } finally {
                setLoading(false);
            }
        } else {
            setPlaceholder('Name is required');
            return;
        }

    };
   
    return (
        <>
            <Toast ref={toast} />
            <Dialog header="Overwrite Names" visible={visible} onHide={onHide} className="overwrite-tags-dialog">
                <div className="overwrite-dialog-content">
                    {documents.length === 0 ? (
                        <h4>No Documents Left For Update</h4>
                    ) : (
                        <>
                            <div className="documents-list">
                                <h3>Documents with Missing Names</h3>
                                <ul>
                                    {documents.map((doc) => (
                                        <li key={doc._id}
                                            className={`overwrite-tags-document-item ${selectedDocument && selectedDocument._id === doc._id ? 'selected' : ''}`}
                                            onClick={() => handleDocumentClick(doc)}>
                                            {doc.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="update-form">
                                {selectedDocument ? (
                                    <>
                                        <h3>Update Name</h3>
                                        <InputText value={updatedName} onChange={(e) => {
                                            setUpdatedName(e.target.value)
                                            setPlaceholder('Enter a name');
                                        }}
                                            placeholder={placeholder}
                                        />
                                        <Button label="Update" onClick={handleUpdate} className="update-button" loading={loading} />
                                    </>
                                ) : (
                                    <p>Select a document to update the name</p>
                                )}
                            </div>
                        </>
                    )}

                </div>
            </Dialog>
        </>

    );
};

export default OverwriteTagsDialog;
