const capitalizeFirsChar = (sentence) =>{
    return sentence
        .split(' ') // Split the sentence into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(' '); // Join the words back into a sentence
  }

export const updateCategories = ( userCategories) => {
    const categoriesData = [{ label: 'All Category', value: 'all' }];
    userCategories.forEach(element => {
        categoriesData.push({ label: capitalizeFirsChar(element + ' category'), value: element});
    });
    return categoriesData;
};

export const calculateAge = (dob) => {
    if(!dob)return null;
    const [day, month, year] = dob.split('/').map(Number);

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}